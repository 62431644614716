import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import Input from 'components/form/input/index';
import TAOOHelmet from 'components/helmet/Helmet';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import useLogin from 'hooks/auth/useLogin';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

import styles from './index.module.scss';
import useLocale from 'hooks/locale/useLocale';
import useToast from 'components/widgets/toast/useToast';
import PrimaryButton from 'components/form/button/PrimaryButton';
import SecondaryButton from 'components/form/button/SecondaryButton';

const notEmptySchema = z.string().min(1);
const mailSchema = z
            .string()
            .min(1)
            .email()
const phoneSchema = z
            .string()
            .min(8)
            .max(8)

function Login() {
    const intl = useIntl();
    const lang = useLocale();
    const navigate = useNavigate();
    const { triggerToast } = useToast({ toastId: 'login' })

    const [type, setType] = useState('phone');
    const [phone, setPhone] = useState(localStorage.getItem('login.saved-phone') || '');
    const [email, setEmail] = useState(localStorage.getItem('login.saved-email') || '');
    const [password, setPassword] = useState(localStorage.getItem('login.saved-password') || '');

    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { submit } = useLogin({ setError, setIsLoading, navigate });

    const handleSubmit = (e) => {
        e.preventDefault();

        let login = null;
        let password = null;

        if(type === 'phone') {
            login = phoneRef.current.value;
            password = null;

            const isEmpty = notEmptySchema.safeParse(login);
            if(!isEmpty.success) {
                triggerToast(intl.formatMessage({ id: 'error.missingField' }), 'warn');

                return;
            }

            // verify login
            const isPhone = phoneSchema.safeParse(login);
            if(!isPhone.success) {
                triggerToast(intl.formatMessage({ id: 'signup.error.phone' }), 'warn');
                return;
            }
            
            setPhone(login);
        } else {
            login = emailRef.current.value;
            password = passwordRef.current.value;

            const isEmpty = notEmptySchema.safeParse(login) || notEmptySchema.safeParse(password);
            if(!isEmpty.success) {
                triggerToast(intl.formatMessage({ id: 'error.missingField' }), 'warn');

                return;
            }

            // verify login
            const isEmail = mailSchema.safeParse(login);
            if(!isEmail.success) {
                triggerToast(intl.formatMessage({ id: 'signup.error.email' }), 'warn');
                return;
            }
            
            setEmail(login);
            setPassword(password);
        }


        submit(login, password).then(() => {
            [
                'login.saved-password', 
                'login.saved-login', 
                'login.saved-phone'
            ].forEach((item) => localStorage.removeItem(item));
        })
    };

    const handleBack = () => {
        navigate(All_PATHS_NAMESPACES[lang].instruction.path);
    };

    const handleChangeView = () => {
        setType(prev => prev === 'phone' ? 'email' : 'phone')
    }

    return (
        <PageTransitionWrapper className="flex-grow-1 d-flex flex-column">
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.login' })} />

            <div onClick={handleBack} className="svg-switch-colored-path mb-1">
                <svg width="42" height="25" viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.967425 11.9029C0.662375 12.2748 0.711287 12.7264 0.91072 13.023L0.91072 13.041L0.979514 13.1134L11.3024 23.974L11.3023 23.974L11.3039 23.9756C11.6478 24.3308 12.2323 24.3392 12.574 24.0122L12.5741 24.0122C12.9139 23.6868 12.9432 23.1004 12.613 22.7512L12.6126 22.7507L3.71919 13.3903L40.3547 13.3903C40.8468 13.3903 41.25 12.9946 41.25 12.5013C41.25 12.008 40.8468 11.6122 40.3547 11.6122L3.71919 11.6122L12.6126 2.25185L12.6129 2.25143C12.9408 1.90474 12.9209 1.31205 12.5711 0.987696C12.224 0.665795 11.6723 0.664494 11.3082 1.02264L11.3081 1.02254L11.3024 1.02863L0.979514 11.8892L0.97322 11.8958L0.967425 11.9029Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </svg>
            </div>
            <h3 className="mb-3">
                <FormattedMessage id="login.greeting" />
            </h3>
            <h4 className="medium mb-3">
                <FormattedMessage id="login.sigin" />
            </h4>
            <form onSubmit={handleSubmit}>
                {type === 'phone' ? (
                    <div className="input-wrapper">
                        <input
                            type="string"
                            name="phone"
                            ref={phoneRef}
                            onChange={(e) => localStorage.setItem('login.saved-phone', e.target.value)}
                            placeholder={intl.formatMessage({
                                id: 'signup.phone-placeholder',
                            })}
                            defaultValue={phone}
                        />
                    </div>
                ): (
                    <>
                        <div className="input-wrapper">
                            <input
                                type="string"
                                name="email"
                                ref={emailRef}
                                onChange={(e) => localStorage.setItem('login.saved-email', e.target.value)}
                                placeholder={intl.formatMessage({
                                    id: 'signup.email-placeholder',
                                })}
                                defaultValue={email}
                            />
                        </div>
                        <div className={`${ styles.label } label`}>
                            <Link to={All_PATHS_NAMESPACES[lang].reset_password.path}>
                                <FormattedMessage id="login.forgetpassword" />
                            </Link>
                        </div>
                        <Input
                            type="password"
                            name="password"
                            ref={passwordRef}
                            onChange={(e) => localStorage.setItem('login.saved-password', e.target.value)}
                            placeholder={intl.formatMessage({
                                id: 'login.password_placeholder',
                            })}
                            autoComplete="off"
                            defaultValue={password}
                        />
                    </>
                )}
                {error && <p className="error">{error}</p>}
                <div className='d-flex flex-column gap-3'>
                    <PrimaryButton type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <Loader fill={LoaderInButtonFillColor} />
                        ) : (
                            <FormattedMessage id="login.submit_form" />
                        )}
                    </PrimaryButton>
                    <SecondaryButton onClick={handleChangeView}>
                        {type === 'email' ? <FormattedMessage id="login.with-phone" /> : <FormattedMessage id="login.with-email" />}
                    </SecondaryButton>
                </div>
            </form>
            <p className="medium mt-auto text-center">
                <FormattedMessage
                    id="login.signup"
                    values={{
                        Link: (msg) => <Link to={All_PATHS_NAMESPACES[lang].sign_up.path}> {msg} </Link>,
                    }}
                />
            </p>
        </PageTransitionWrapper>
    );
}

export default Login;
