import { ButtonHTMLAttributes } from "react";

const SecondaryButton = ({ type, children, className, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button 
        type={type || 'button'} 
        className={`btn btn-lg-secondary ${className || ''}`} 
        {...rest}
    >
        {children}
    </button>
);


SecondaryButton.defaultProps = {
    className: ''
}

export default SecondaryButton;
