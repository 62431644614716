import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { application_type, endpoints } from 'constants/api';
import { ErrorMessages } from 'constants/index';
import useLocale from 'hooks/locale/useLocale';
import TaooServerClient from 'http/axios';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { setEmailPhone, setPassword } from 'store/login-process/action';
import { toggleStoreUpdate } from 'store/update-handler/updateHandler.action';
import { setCurrentUser } from 'store/user/action';
import { setStorageItem } from 'services/storage';

export default function useLogin({ setError, setIsLoading, navigate }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const lang = useLocale();

    const submit = async (login, password, subscribe = false) => {
        setIsLoading(true);

        return TaooServerClient.post(endpoints.auth.login, {
            login,
            password,
            subscribe,
            type: 'CLIENT',
        })
                .then((res) => {
                    const { message = '', success = false, challenge = '' } = res.data;

                    if (
                        !success &&  [
                            ErrorMessages.auth_process.USER_UNVERIFIED, 
                            ErrorMessages.auth_process.USER_SINGLE_SIGN_IN
                        ].includes(message)
                    ) {
                        setIsLoading(false);
                        dispatch(setEmailPhone(login));
                        dispatch(setPassword(password));
                        setStorageItem('sign-up-challenge', challenge);
                        setStorageItem('sign-up-message', message);
                        navigate(All_PATHS_NAMESPACES[lang].sign_up_check_pin.path);

                        return;
                    }

                    if (!success && message === ErrorMessages.auth_process.USER_AUTH_FAILED) {
                        setIsLoading(false);
                        setError(
                            intl.formatMessage({
                                id: ErrorMessages.auth_process.USER_AUTH_FAILED,
                            }),
                        );

                        return;
                    }

                    const {
                        id, firstName, lastName, extra, token,
                    } = res.data;

                    const user = {
                        id,
                        login,
                        firstName,
                        lastName,
                        token,
                        hasPin:         true,
                        pin:            extra?.pinChallenge,
                        onboarded:      extra?.onboarded,
                        onboardingStep: extra?.onboardingStep,
                        totalPoints:    extra?.totalPoints,
                        tooltips:       extra?.tooltips,
                    };

                    dispatch(setCurrentUser(user));
                    dispatch(toggleStoreUpdate(true));

                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);

                    setIsLoading(false);
                });
    };

    const loginWithToken = async (token) => {
        setIsLoading(true);

        return TaooServerClient.post(endpoints.auth.tokenLogin, {
            token,
        })
                .then((res) => {
                    const { message = '', success = false, challenge = '' } = res.data;

                    if (!success && ErrorMessages.auth_process.USER_SUBSCRIPTION_NOT_FOUND === message) {
                        setIsLoading(false);
                        setStorageItem('sign-up-message', message);
                        setError(
                            intl.formatMessage({
                                id: ErrorMessages.auth_process.USER_SUBSCRIPTION_NOT_FOUND,
                            }),
                        );

                        return;
                    }

                    if (!success && ErrorMessages.auth_process.USER_SUBSCRIPTION_EXPIRED === message) {
                        setIsLoading(false);
                        setStorageItem('sign-up-message', message);
                        setError(
                            intl.formatMessage({
                                id: ErrorMessages.auth_process.USER_SUBSCRIPTION_EXPIRED,
                            }),
                        );

                        return;
                    }

                    if (
                        !success &&  [
                            ErrorMessages.auth_process.USER_UNVERIFIED, 
                            ErrorMessages.auth_process.USER_SINGLE_SIGN_IN
                        ].includes(message)
                    ) {
                        setIsLoading(false);
                        dispatch(setEmailPhone(login));
                        dispatch(setPassword(password));
                        setStorageItem('sign-up-challenge', challenge);
                        setStorageItem('sign-up-message', message);
                        navigate(All_PATHS_NAMESPACES[lang].sign_up_check_pin.path);

                        return;
                    }

                    if (!success && message === ErrorMessages.auth_process.USER_AUTH_FAILED) {
                        setIsLoading(false);
                        setError(
                            intl.formatMessage({
                                id: ErrorMessages.auth_process.USER_AUTH_FAILED,
                            }),
                        );

                        return;
                    }

                    const {
                        id, firstName, lastName, extra, token,
                    } = res.data;

                    const user = {
                        id,
                        login: null,
                        firstName,
                        lastName,
                        token,
                        hasPin:         true,
                        pin:            extra?.pinChallenge,
                        onboarded:      extra?.onboarded,
                        onboardingStep: extra?.onboardingStep,
                        totalPoints:    extra?.totalPoints,
                        tooltips:       extra?.tooltips,
                    };

                    dispatch(setCurrentUser(user));
                    dispatch(toggleStoreUpdate(true));

                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);

                    setIsLoading(false);
                });
    }

    return { submit, loginWithToken };
}
