import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import useLogin from 'hooks/auth/useLogin';
import useToast from 'components/widgets/toast/useToast';
import PrimaryButton from 'components/form/button/PrimaryButton';

import './loginOrange.scss';

const notEmptySchema = z.string().min(1);
const phoneSchema = z
        .string()
        .min(8)
        .max(8);

const LoginOrange = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { triggerToast } = useToast({ toastId: 'login' });

    const [phone, setPhone] = useState(localStorage.getItem('login.saved-phone') || '');

    const phoneRef = useRef(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { submit, loginWithToken } = useLogin({ setError, setIsLoading, navigate });
    const [searchParams] = useSearchParams();

    const loginToken = searchParams.get('token');

    useEffect(() => {
        if (loginToken) {
            loginWithToken(loginToken);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const login = phoneRef.current.value;

        const isEmpty = notEmptySchema.safeParse(login);

        if (!isEmpty.success) {
            triggerToast(intl.formatMessage({ id: 'error.missingField' }), 'warn');

            return;
        }

        // verify login
        const isPhone = phoneSchema.safeParse(login);

        if (!isPhone.success) {
            triggerToast(intl.formatMessage({ id: 'signup.error.phone' }), 'warn');

            return;
        }

        setPhone(login);


        submit(login, null, true).then(() => {
            [
                'login.saved-login',
            ].forEach((item) => localStorage.removeItem(item));
        });
    };

    return (
        <PageTransitionWrapper className="flex-grow-1 d-flex flex-column">
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.login' })} />

            <h3 className="mb-3 mt-5">
                <FormattedMessage id="login-orange.greeting" />
            </h3>
            <h4 className="medium mb-3">
                <FormattedMessage id="login-orange.sigin" />
            </h4>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input
                        type="string"
                        name="phone"
                        ref={phoneRef}
                        onChange={(e) => localStorage.setItem('login.saved-phone', e.target.value)}
                        placeholder={intl.formatMessage({
                            id: 'signup.phone-placeholder',
                        })}
                        defaultValue={phone}
                    />
                </div>
                {error && <p className="error">{error}</p>}
                <div className="d-flex flex-column gap-3">
                    <PrimaryButton type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <Loader fill={LoaderInButtonFillColor} />
                        ) : (
                            <FormattedMessage id="login.submit_form" />
                        )}
                    </PrimaryButton>
                </div>
            </form>
        </PageTransitionWrapper>
    );
};

export default LoginOrange;
