export const isValidNumber = (value) => value !== null && value !== undefined && !isNaN(value);

export const isValidPhoneNumber = (value) => {
    if (isNaN(value)) {
        return { valid: false, messageId: 'error.phone.invalid-type', messageType: 'warn' };
    }

    if (String(value).length !== 8) {
        return { valid: false, messageId: 'error.phone.invalid-length', messageType: 'warn' };
    }

    return { valid: true };
};

export const isValidPassword = (password) => {
    if (!password) {
        return { valid: false, messageId: 'warn.password.validate.empty', messageType: 'warn' };
    }

    if (String(password).length < 8) {
        return { valid: false, messageId: 'warn.password.validate.less-than-min', messageType: 'warn' };
    }

    const isAlphaNumeric = /^[0-9a-zA-Z]+$/.test(password);

    if (!isAlphaNumeric) {
        return { valid: false, messageId: 'warn.password.validate.format', messageType: 'warn' };
    }

    return { valid: true };
};

export const neitherNullNorUndefined = (x) => x != null && x !== undefined;

export const isNumeric = (num) => !isNaN(num);
