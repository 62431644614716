import { neitherNullNorUndefined } from 'services/validation';

import * as types from './updateHandler.types';

export const toggleStoreUpdate = (shouldUpdateStore) => (dispatch) => {
    if (!neitherNullNorUndefined(shouldUpdateStore)) {
        console.error('[toggleStoreUpdate]: the parameter shouldUpdateStore is required!');

        return;
    }

    dispatch({ type: types.UPDATE_HANDLER_TRIGGER_STORE_UPDATE, shouldUpdateStore });
};
