import React from 'react';

import styles from './index.module.scss';

const Input = React.forwardRef((props, ref) => {
    class Input extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                passwordType: 'password',
            };
        }

        handlePasswordType = () => {
            this.setState({
                passwordType: this.state.passwordType === 'password' ? 'text' : 'password',
            });
        };

        render() {
            const {
                type = 'text',
                placeholder = '',
                name,
                maxLength = null,
                id = null,
                defaultValue = '',
                onChange=null
            } = this.props;

            const { passwordType } = this.state;
            const { handlePasswordType } = this;

            return (
                <div className="input-wrapper">
                    {(type === 'text' || type === 'email') && (
                        <input
                            id={id || name}
                            ref={ref}
                            type={type}
                            placeholder={placeholder}
                            name={name}
                            defaultValue={defaultValue}
                        />
                    )}
                    {type === 'password' && (
                        <>
                            <input
                                id={id || name}
                                maxLength={maxLength}
                                ref={ref}
                                type={passwordType}
                                placeholder={placeholder}
                                name={name}
                                defaultValue={defaultValue}
                                onChange={onChange}
                            />
                            <div onClick={handlePasswordType} className={`${styles.svg_wrapper} svg-wrapper`}>
                                <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
                                    <path
                                        d="M9.61094 13.4905C10.366 13.4861 11.1164 13.3735 11.8395 13.1563L11.3817 11.6024C10.8072 11.7756 10.211 11.8656 9.61097 11.8697C6.13854 11.8697 3.08749 8.65055 2.04414 7.41271C2.74876 6.57626 3.53236 5.80963 4.38408 5.12338L3.37112 3.85718C2.24413 4.75767 1.22986 5.79096 0.350467 6.93463L0 7.41271L0.350467 7.89079C0.518633 8.11973 4.50578 13.4907 9.61112 13.4907L9.61094 13.4905Z"
                                        fill="#374053"
                                    />
                                    <path
                                        d="M5.7981 7.41272C5.79965 8.42344 6.20183 9.3923 6.91662 10.1069C7.63127 10.8217 8.60013 11.2239 9.61085 11.2255V9.6047V9.60484C9.02977 9.60371 8.47282 9.37237 8.06203 8.96157C7.65124 8.55077 7.41988 7.99383 7.41876 7.41275L5.7981 7.41272Z"
                                        fill="#374053"
                                    />
                                    <path
                                        d="M17.0236 13.6811L14.9673 11.6227C16.4513 10.585 17.7678 9.3266 18.8712 7.89105L19.2217 7.41297L18.8712 6.93489C18.703 6.70595 14.7159 1.33501 9.61054 1.33501C8.24286 1.35861 6.90022 1.70568 5.69255 2.34797L3.34244 0L2.1958 1.14664L15.8791 14.8256L17.0236 13.6811ZM9.6109 2.95604C13.0833 2.95604 16.1326 6.17524 17.1777 7.41308C16.1997 8.57979 15.0621 9.60278 13.7986 10.452L12.8201 9.47348H12.8199C13.4504 8.48978 13.5949 7.27148 13.2124 6.16764C12.8298 5.0638 11.9623 4.19627 10.8585 3.81359C9.7545 3.43105 8.53623 3.57561 7.55264 4.20604L6.90836 3.56389C7.75769 3.17712 8.67779 2.97008 9.61091 2.95608L9.6109 2.95604ZM8.74377 5.40126C9.28419 5.1671 9.89595 5.16046 10.4413 5.38275C10.9866 5.60504 11.4193 6.03748 11.642 6.58267C11.8646 7.12787 11.8584 7.73962 11.6247 8.28018L8.74377 5.40126Z"
                                        fill="#374053"
                                    />
                                </svg>
                            </div>
                        </>
                    )}
                    {type === 'search' && (
                        <>
                            <input
                                className={styles.search}
                                id={id || name}
                                ref={ref}
                                type="search"
                                placeholder={placeholder}
                                name={name}
                                defaultValue={defaultValue}
                            />
                            <div className={`${styles.svg_wrapper_search} svg-wrapper`}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <g clipPath="url(#clip0_281_750)">
                                        <path
                                            d="M21.7916 20.8031L16.1116 15.2138C17.599 13.5978 18.5129 11.4606 18.5129 9.10875C18.5122 4.07781 14.3684 0 9.25628 0C4.14421 0 0.000366211 4.07781 0.000366211 9.10875C0.000366211 14.1397 4.14421 18.2175 9.25628 18.2175C11.4651 18.2175 13.4909 17.4535 15.0822 16.1834L20.7842 21.7947C21.0621 22.0684 21.5131 22.0684 21.791 21.7947C22.0695 21.521 22.0695 21.0768 21.7916 20.8031ZM9.25628 16.8161C4.93091 16.8161 1.42452 13.3654 1.42452 9.10875C1.42452 4.85211 4.93091 1.40143 9.25628 1.40143C13.5817 1.40143 17.088 4.85211 17.088 9.10875C17.088 13.3654 13.5817 16.8161 9.25628 16.8161Z"
                                            fill="#A7A7A7"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_281_750">
                                            <rect width="22" height="22" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </>
                    )}

                    {type === 'date' && (
                        <input className={styles.search} id={id || name} ref={ref} type="date" name={name} />
                    )}
                </div>
            );
        }
    }

    return <Input {...props} />;
});

export default Input;
